import { REPAYMENT_PLAN_TYPES } from '../constants';

const ibrHighlights = [
  'Payments count toward Public Service Loan Forgiveness (PSLF)',
  `Payment amounts are capped — you\u2019ll never exceed what you would owe on the standard 10-year plan`,
];

const ibrKeepInMind = [
  'Your eligibility may change as your income grows, but you can switch to other IDR plans anytime',
  `If you leave this plan, or your eligibility changes, your unpaid interest will be added to your total loan amount`,
  `If you are not in or leave public service, you will reach forgiveness in 20-25 years`,
];

export const planHighlightMap = new Map([
  [
    REPAYMENT_PLAN_TYPES.SAVE,
    [
      'Your loans will be placed in administrative forbearance: your payments pause and interest drops to 0%',
    ],
  ],
  [
    REPAYMENT_PLAN_TYPES.PAYE,
    [
      'Payments count toward Public Service Loan Forgiveness (PSLF)',
      'Payment amounts are capped — you\u2019ll never exceed what you would owe on the standard 10-year plan',
    ],
  ],
  [
    REPAYMENT_PLAN_TYPES.ICR,
    [
      'Payments count toward Public Service Loan Forgiveness (PSLF)',
      `Payment amounts are capped — you\u2019ll never exceed what you would owe on a 12-year plan`,
    ],
  ],
  [REPAYMENT_PLAN_TYPES.IBR, ibrHighlights],
  [REPAYMENT_PLAN_TYPES.IBR_NEW_BORROWER, ibrHighlights],
]);

export const planKeepInMindMap = new Map([
  [
    REPAYMENT_PLAN_TYPES.SAVE,
    [
      'Administrative forbearance doesn’t count towards Public Service Loan Forgiveness (PSLF)',
      `Due to pending legislation, this plan may change in the future`,
    ],
  ],
  [
    REPAYMENT_PLAN_TYPES.PAYE,
    [
      'Your eligibility may change as your income grows, but you can switch to other IDR plans anytime',
      `If you are not in or leave public service, you will reach forgiveness in 20 years`,
    ],
  ],
  [
    REPAYMENT_PLAN_TYPES.ICR,
    [
      'Parent PLUS loans may be eligible but must be converted to a Direct Consolidation Loan. We’ll help you consolidate if needed',
      `If you are not in or leave public service, you will reach forgiveness in 25 years`,
    ],
  ],
  [REPAYMENT_PLAN_TYPES.IBR, ibrKeepInMind],
  [REPAYMENT_PLAN_TYPES.IBR_NEW_BORROWER, ibrKeepInMind],
]);
